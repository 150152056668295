:root {
  --contexify-zIndex: 666;
  --contexify-menu-minWidth: 220px;
  --contexify-menu-padding: 6px;
  --contexify-menu-radius: 6px;
  --contexify-menu-bgColor: #fff;
  --contexify-menu-shadow: 1px 2px 2px #0000001a, 2px 4px 4px #0000001a, 3px 6px 6px #0000001a;
  --contexify-menu-negatePadding: var(--contexify-menu-padding);
  --contexify-separator-color: #0003;
  --contexify-separator-margin: 5px;
  --contexify-itemContent-padding: 6px;
  --contexify-activeItem-radius: 4px;
  --contexify-item-color: #333;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #3498db;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}

@keyframes contexify_feedback {
  0% {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
}

.contexify {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--contexify-menu-bgColor);
  box-sizing: border-box;
  box-shadow: var(--contexify-menu-shadow);
  border-radius: var(--contexify-menu-radius);
  padding: var(--contexify-menu-padding);
  min-width: var(--contexify-menu-minWidth);
  z-index: var(--contexify-zIndex);
  position: fixed;
}

.contexify_submenu-isOpen, .contexify_submenu-isOpen > .contexify_itemContent {
  color: var(--contexify-activeItem-color);
  background-color: var(--contexify-activeItem-bgColor);
  border-radius: var(--contexify-activeItem-radius);
}

.contexify_submenu-isOpen > .contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-activeArrow-color);
}

.contexify_submenu-isOpen > .contexify_submenu {
  pointer-events: auto;
  opacity: 1;
}

.contexify .contexify_submenu {
  pointer-events: none;
  top: calc(-1 * var(--contexify-menu-negatePadding));
  transition: opacity .265s;
  position: absolute;
  left: 100%;
}

.contexify .contexify_submenu-bottom {
  bottom: calc(-1 * var(--contexify-menu-negatePadding));
  top: unset;
}

.contexify .contexify_submenu-right {
  right: 100%;
  left: unset;
}

.contexify_rightSlot {
  color: var(--contexify-rightSlot-color);
  margin-left: auto;
  display: flex;
}

.contexify_separator {
  cursor: default;
  margin: var(--contexify-separator-margin);
  background-color: var(--contexify-separator-color);
  height: 1px;
}

.contexify_willLeave-disabled {
  pointer-events: none;
}

.contexify_item {
  cursor: pointer;
  position: relative;
}

.contexify_item:focus {
  outline: 0;
}

.contexify_item:focus .contexify_rightSlot, .contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-activeRightSlot-color);
}

.contexify_item:not(.contexify_item-disabled)[aria-haspopup] > .contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-arrow-color);
}

.contexify_item:not(.contexify_item-disabled)[aria-haspopup].contexify_submenu-isOpen > .contexify_itemContent .contexify_rightSlot, .contexify_item:not(.contexify_item-disabled)[aria-haspopup]:hover > .contexify_itemContent .contexify_rightSlot, .contexify_item[aria-haspopup]:focus > .contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-activeArrow-color);
}

.contexify_item:not(.contexify_item-disabled):focus > .contexify_itemContent, .contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent {
  color: var(--contexify-activeItem-color);
  background-color: var(--contexify-activeItem-bgColor);
  border-radius: var(--contexify-activeItem-radius);
}

.contexify_item:not(.contexify_item-disabled):hover > .contexify_submenu {
  pointer-events: auto;
  opacity: 1;
}

.contexify_item-disabled {
  cursor: default;
  opacity: .5;
}

.contexify_itemContent {
  padding: var(--contexify-itemContent-padding);
  white-space: nowrap;
  color: var(--contexify-item-color);
  align-items: center;
  display: flex;
  position: relative;
}

.contexify_item-feedback {
  animation: .12s both contexify_feedback;
}

.contexify_theme-dark {
  --contexify-menu-bgColor: #282828fa;
  --contexify-separator-color: #4c4c4c;
  --contexify-item-color: #fff;
}

.contexify_theme-light {
  --contexify-separator-color: #eee;
  --contexify-item-color: #666;
  --contexify-activeItem-color: #3498db;
  --contexify-activeItem-bgColor: #e0eefd;
  --contexify-activeRightSlot-color: #3498db;
  --contexify-active-arrow-color: #3498db;
}

@keyframes contexify_scaleIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 1;
  }
}

@keyframes contexify_scaleOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.contexify_willEnter-scale {
  transform-origin: 0 0;
  animation: .3s contexify_scaleIn;
}

.contexify_willLeave-scale {
  transform-origin: 0 0;
  animation: .3s contexify_scaleOut;
}

@keyframes contexify_fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes contexify_fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.contexify_willEnter-fade {
  animation: .3s contexify_fadeIn;
}

.contexify_willLeave-fade {
  animation: .3s contexify_fadeOut;
}

@keyframes contexify_flipInX {
  0% {
    transform: perspective(800px)rotateX(45deg);
  }

  to {
    transform: perspective(800px);
  }
}

@keyframes contexify_flipOutX {
  0% {
    transform: perspective(800px);
  }

  to {
    opacity: 0;
    transform: perspective(800px)rotateX(45deg);
  }
}

.contexify_willEnter-flip {
  animation: .3s contexify_flipInX;
}

.contexify_willEnter-flip, .contexify_willLeave-flip {
  transform-origin: top;
  backface-visibility: visible !important;
}

.contexify_willLeave-flip {
  animation: .3s contexify_flipOutX;
}

@keyframes contexify_slideIn {
  0% {
    opacity: 0;
    transform: scaleY(.3);
  }

  to {
    opacity: 1;
  }
}

@keyframes contexify_slideOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scaleY(.3);
  }
}

.contexify_willEnter-slide {
  transform-origin: top;
  animation: .3s contexify_slideIn;
}

.contexify_willLeave-slide {
  transform-origin: top;
  animation: .3s contexify_slideOut;
}
/*# sourceMappingURL=index.55f7eb04.css.map */
